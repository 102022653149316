export default {
  data () {
    return {
      statistics: [
        {
          title: 'Business Development',
          value: 2
        },
        {
          title: 'Operations',
          value: 2
        },
        {
          title: 'Development',
          value: 22
        },
        {
          title: 'Marketing & PR',
          value: 3
        },
        {
          title: 'Creative',
          value: 2
        },
        {
          title: 'Research',
          value: 1
        }
      ]
    }
  }
}
