import * as directives from 'src/directives'

export default {
  directives: {
    ...directives
  },
  components: {
  },
  data () {
    return {}
  }
}
