import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import lax from 'lax.js'

export default async () => {
  Vue.use(Vuetify, {
    iconfont: 'md'
  })

  if (process.client) {
    lax.setup()

    const updateLax = () => {
      lax.update(window.scrollY)
      window.requestAnimationFrame(updateLax)
    }

    window.requestAnimationFrame(updateLax)
  }
}
