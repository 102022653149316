import * as directives from 'src/directives'
// eslint-disable-next-line import/no-webpack-loader-syntax
import timeWarpSvg from '!!raw-loader!./time-warp.svg'

export default {
  directives: {
    ...directives
  },
  data () {
    return {
      timeWarpSvg,
      timeWarpSvgEl: null,
      observer: null
    }
  },
  mounted () {
    this.timeWarpSvgEl = this.$refs.image.querySelector('svg')
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8
    }
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const { boundingClientRect, intersectionRect } = entry
          const visiblePart = intersectionRect.height / boundingClientRect.height
          this.onScroll(visiblePart)
        } else {
          this.timeWarpSvgEl.querySelectorAll('.animation.hide').forEach(anim => anim.beginElement())
        }
      })
    }, options)
    this.observer.observe(this.timeWarpSvgEl)
  },
  beforeDestroy () {
    this.observer.unobserve(this.timeWarpSvgEl)
  },
  methods: {
    onScroll (visiblePart) {
      if (visiblePart > 0.8) {
        this.timeWarpSvgEl.querySelectorAll('.animation.show').forEach(anim => anim.beginElement())
      }
    }
  }
}
