import '@babel/polyfill'
import 'es6-promise/auto'
import 'setimmediate'
import 'swiper/dist/css/swiper.min.css'

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'

import { sync } from 'vuex-router-sync'

Vue.use(Vuelidate)

export default function ({ router, store }) {
  sync(store, router)

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  return app
}
