import * as directives from 'src/directives'

export default {
  directives: {
    ...directives
  },
  data () {
    return {
      keys: [
        {
          name: 'PancakeSwap',
          sub: '(BSC)',
          link: 'https://pancakeswap.finance/swap?outputCurrency=0x3b198e26E473b8faB2085b37978e36c9DE5D7f68',
          img: '/assets/img/pancake_logo.svg'
        },
        {
          name: '1inch Network',
          sub: '(Multichain)',
          link: 'https://app.1inch.io/#/56/simple/swap/BNB/TIME',
          img: '/assets/img/one_inch_logo.svg'
        },
        {
          name: 'QuickSwap',
          sub: '(Polygon)',
          link: 'https://quickswap.exchange/#/swap?inputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619&outputCurrency=0xb970bc1bd4fcd639c37aa8efd6713eadc577252f&swapIndex=0',
          img: '/assets/img/quick_swap_logo.svg'
        }
      ]
    }
  }
}
