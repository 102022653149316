var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"root-about-product-section"},[_c('div',{staticClass:"about-product-section",class:{
    'about-product-section-ltr': _vm.product.stereotype === 'LTR',
    'about-product-section-rtl': _vm.product.stereotype === 'RTL'
  }},[_c('div',{staticClass:"inner-wrap"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column column-image"},[_c('div',{ref:"canvas",staticClass:"img-block"},[_c('div',{staticClass:"overlay",style:({
              display: _vm.scaleX != null && _vm.scaleY != null,
              transform: `scale(${_vm.scaleX}, ${_vm.scaleY})`
            })},_vm._l((_vm.animations),function(animation,i){return _c('img',{key:i,staticClass:"layer lax-scroll-animate",style:({
                  left: animation.left,
                  top: animation.top
                }),attrs:{"data-lax-translate-x":animation.laxTranslateX,"data-lax-translate-y":animation.laxTranslateY,"data-lax-opacity":animation.laxOpacity,"src":animation.icon,"alt":`Animation #${1} for ${_vm.product.name}`,"loading":"lazy"}})}),0),_c('img',{ref:"image",staticClass:"main",attrs:{"src":_vm.product.animationImg,"alt":_vm.product.name,"loading":"lazy"},on:{"load":_vm.onCanvasLoad}})])]),_c('div',{staticClass:"column column-text"},[_c('div',{directives:[{name:"infocus",rawName:"v-infocus",value:('slideInUp'),expression:"'slideInUp'"}],staticClass:"about-block helper-slideInUp"},[_c('header',[_c('div',{staticClass:"icon-block",style:({
                'background-image': `url('${_vm.product.logo}')`
                })}),_c('h2',{staticClass:"title-about"},[_vm._v(_vm._s(_vm.product.name))])]),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.product.text))]),_c('router-link',{staticClass:"v-btn btn-purple",attrs:{"to":`/product/${_vm.product.id}`}},[_vm._v("Learn More")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }