import { render, staticRenderFns } from "./TokenWidgetPartial.htm?vue&type=template&id=cdb8c61a&scoped=true&external"
import script from "./TokenWidgetPartial.js?vue&type=script&lang=js&external"
export * from "./TokenWidgetPartial.js?vue&type=script&lang=js&external"
import style0 from "./TokenWidgetPartial.scss?vue&type=style&index=0&id=cdb8c61a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdb8c61a",
  null
  
)

export default component.exports