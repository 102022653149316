import Swiper from 'swiper'
import { debounce } from 'lodash'
import * as directives from 'src/directives'

const TYPE_CHRONO = 1
const TYPE_PX = 2
const TYPE_TX = 3
const TYPE_LX = 4
const TYPE_AUDT = 5

export default {
  directives: {
    ...directives
  },
  data: () => ({
    categories: [
      {
        name: 'All',
        type: null
      },
      {
        name: 'Chrono.tech',
        type: TYPE_CHRONO
      },
      {
        name: 'LaborX',
        type: TYPE_LX
      },
      {
        name: 'TimeX',
        type: TYPE_TX
      },
      {
        name: 'PaymentX',
        type: TYPE_PX
      },
      {
        name: 'AUDT',
        type: TYPE_AUDT
      }
    ],
    activeType: null,
    milestones: [
      {
        name: 'Chrono.tech foundation',
        date: 'Oct 16',
        type: TYPE_CHRONO
      },
      {
        name: 'Successful token sale',
        date: 'Feb 17',
        type: TYPE_CHRONO
      },
      {
        name: 'TIME listed on exchanges',
        date: 'Mar 17',
        type: TYPE_CHRONO
      },
      {
        name: 'Beta LaborX community testing',
        date: 'Aug 18',
        type: TYPE_LX
      },
      {
        name: 'Beta TimeX DEX launch',
        date: 'Feb 19',
        type: TYPE_TX
      },
      {
        name: 'Beta PaymentX launch',
        date: 'Sep 19',
        type: TYPE_PX
      },
      {
        name: 'Beta TimeX Plasma launch',
        date: 'Oct 19',
        type: TYPE_TX
      },
      {
        name: 'LaborX Beta launch',
        date: 'Nov 19',
        type: TYPE_LX
      },
      {
        name: 'TimeX integrated XRP Blockchain',
        date: 'Jan 20',
        type: TYPE_TX
      },
      {
        name: 'TimeX integrated Litecoin',
        date: 'Feb 20',
        type: TYPE_TX
      },
      {
        name: 'AustralianDollar Token (AUDT) launch',
        date: 'Mar 20',
        type: TYPE_AUDT
      },
      {
        name: 'LaborX redesign',
        date: 'Apr 20',
        type: TYPE_LX
      },
      {
        name: 'LaborX launch',
        date: 'May 20',
        type: TYPE_LX
      },
      {
        name: 'Referral system launch on TimeX',
        date: 'Jun 20',
        type: TYPE_TX
      },
      {
        name: 'Gigs Module launch on LaborX',
        date: 'Aug 20',
        type: TYPE_LX
      },
      {
        name: 'LaborX integrated BNB Chain',
        date: 'Sep 20',
        type: TYPE_LX
      },
      {
        name: 'LaborX integrated MetaMask and WalletConnect',
        date: 'Nov 20',
        type: TYPE_LX,
        class: 'lx-nov-20'
      },
      {
        name: 'Fiat Deposit System integration',
        date: 'Dec 20',
        type: TYPE_LX
      },
      {
        name: 'TimeX integrated Advcash',
        date: 'Jan 21',
        type: TYPE_TX
      },
      {
        name: 'LaborX integration with 1inch',
        date: 'Feb 21',
        type: TYPE_LX,
        class: 'lx-feb-21'
      },
      {
        name: 'Simple (convert) mode launch on TimeX',
        date: 'Feb 21',
        type: TYPE_TX
      },
      {
        name: 'TimeX integrated Monoova and RapidID (KYC provider)',
        date: 'Mar 21',
        type: TYPE_TX
      },
      {
        name: 'TimeX integrated BNB Chain',
        date: 'Apr 21',
        type: TYPE_TX
      },
      {
        name: 'TimeWarp launch',
        date: 'May 21',
        type: TYPE_CHRONO
      },
      {
        name: 'TimeX integrated TradingView',
        date: 'Jun 21',
        type: TYPE_TX
      },
      {
        name: 'TIME on Binance Smart Chain launch',
        date: 'Jun 21',
        type: TYPE_CHRONO
      },
      {
        name: 'TimeX integrated Itez',
        date: 'Jul 21',
        type: TYPE_TX
      },
      {
        name: 'Chrono.tech partnership with Crypto Gaming United',
        date: 'Aug 21',
        type: TYPE_CHRONO
      },
      {
        name: 'TimeX redesign',
        date: 'Aug 21',
        type: TYPE_TX
      },
      {
        name: 'TIME on Polygon launch',
        date: 'Sep 21',
        type: TYPE_CHRONO
      },
      {
        name: 'TimeX integrated Ronin Chain',
        date: 'Nov 21',
        type: TYPE_TX
      },
      {
        name: 'TIME/USDT Trading Pair added on Kucoin',
        date: 'Dec 21',
        type: TYPE_CHRONO
      },
      {
        name: 'TimeX integrated Waves',
        date: 'Dec 21',
        type: TYPE_TX
      },
      {
        name: '50,000 registered users in TimeX',
        date: 'Jan 22',
        type: TYPE_TX
      },
      {
        name: 'TIME ad campaign on Times Square',
        date: 'Feb 22',
        type: TYPE_CHRONO
      },
      {
        name: 'LaborX partnered with $SHIB community',
        date: 'Feb 22',
        type: TYPE_LX
      },
      {
        name: 'TIME listed on Gate.io',
        date: 'Mar 22',
        type: TYPE_CHRONO
      },
      {
        name: 'LaborX launched Full-time Jobs module in beta',
        date: 'Apr 22',
        type: TYPE_LX
      },
      {
        name: 'TIME listed on Coinbase',
        date: 'Jun 22',
        type: TYPE_CHRONO
      },
      {
        name: 'LaborX redesign',
        date: 'Jun 22',
        type: TYPE_LX
      },
      {
        name: 'LaborX NFT launch in partnership with Binance NFT',
        date: 'Jul 22',
        type: TYPE_LX
      },
      {
        name: 'TimeX integrated Polygon Chain',
        date: 'Oct 22',
        type: TYPE_TX
      },
      {
        name: '100,000 registered users in LaborX',
        date: 'Dec 22',
        type: TYPE_LX
      },
      {
        name: 'TimeX integrated Tron Network',
        date: 'Jan 23',
        type: TYPE_TX
      },
      {
        name: 'TIME listed on Bitget and Bybit. Winning a vote on Bybit',
        date: 'Jan 23',
        type: TYPE_CHRONO
      },
      {
        name: 'TimeX Android App Released',
        date: 'Feb 23',
        type: TYPE_TX
      },
      {
        name: 'LaborX Launches Dedicated Chat For Users',
        date: 'Feb 23',
        type: TYPE_LX
      },
      {
        name: 'TimeX iOS App: Beta Release',
        date: 'Mar 23',
        type: TYPE_TX
      },
      {
        name: 'Announcing A New Partnership With SingularityNET',
        date: 'Mar 23',
        type: TYPE_LX
      },
      {
        name: 'New Recruitment Module Goes Live On LaborX',
        date: 'Jul 23',
        type: TYPE_LX
      },
      {
        name: 'Integrating AI Into The LaborX Workflow',
        date: 'Sep 23',
        type: TYPE_LX
      }
    ].reverse(),
    swiper: null
  }),
  computed: {
    activeCategory () {
      return this.categories.find(category => category.type === this.activeType)
    }
  },
  methods: {
    setActiveType (category) {
      this.activeType = category.type
    },
    erase () {
      if (this.swiper) {
        this.swiper.destroy()
        this.swiper = null
      }
    },
    draw () {
      // eslint-disable-next-line
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 'auto',
        grabCursor: true,
        watchSlidesVisibility: true,
        centeredSlides: document.body.getBoundingClientRect().width <= 450,
        navigation: {
          nextEl: '.control.right',
          prevEl: '.control.left'
        }
      })
    },
    redraw: debounce(async function () {
      const swiper = this.$refs.swiper
      if (swiper != null && this.resizeListener != null) {
        this.erase()
        this.draw()
      }
    }, 200)
  },
  mounted () {
    this.resizeListener = () => {
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(() => {
          this.redraw()
        })
      } else {
        setImmediate(() => {
          this.redraw()
        })
      }
    }
    window.addEventListener('resize', this.resizeListener)
    this.draw()
  },
  beforeDestroy () {
    if (this.resizeListener != null) {
      window.removeEventListener('resize', this.resizeListener)
      this.resizeListener = null
    }
    if (this.swiper != null) {
      this.swiper.destroy()
      this.swiper = null
    }
  }
}
