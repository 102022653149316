import copyItem from 'src/components/default/CopyItem/CopyItem.vue'
import * as directives from 'src/directives'

export default {
  directives: {
    ...directives
  },
  props: {
    data: Object
  },
  components: {
    copyItem
  }
}
