import { mapState } from 'vuex'
import brandPartial from 'src/partials/default/BrandPartial/BrandPartial.vue'
import aboutPartial from 'src/partials/default/AboutPartial/AboutPartial.vue'
import milestonesPartial from 'src/partials/default/MilestonesPartial/MilestonesPartial.vue'
import blogPartial from 'src/partials/default/BlogPartial/BlogPartial.vue'
import partnersPartial from 'src/partials/default/PartnersPartial/PartnersPartial.vue'
import faqPartial from 'src/partials/default/FaqPartial/FaqPartial.vue'
import communityPartial from 'src/partials/default/CommunityPartial/CommunityPartial.vue'
import timeWarpTimer from 'src/partials/default/TimeWarpTimer/TimeWarpTimer.vue'
import stakingTimePartial from 'src/partials/default/StakingTimePartial/StakingTimePartial.vue'
// import resultsOfYearPartial from 'src/partials/default/ResultsOfYearPartial/ResultsOfYearPartial.vue'

export default {
  components: {
    brandPartial,
    aboutPartial,
    milestonesPartial,
    blogPartial,
    partnersPartial,
    faqPartial,
    communityPartial,
    timeWarpTimer,
    stakingTimePartial
    // resultsOfYearPartial
  },
  head () {
    return {
      title: 'Australian Blockchain Company',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Chrono.tech is the fastest-growing Australian blockchain company. We believe that blockchain technology is the key to revolutionising global recruitment and payment processes.'
        },
        {
          vmid: 'google-site-verification',
          name: 'google-site-verification',
          content: 'KyzDq_079Q38qJR-MyDV4OLPnkHDo9W3FyQs_H4FgMc'
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Australian Blockchain Company'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          // eslint-disable-next-line max-len
          content: 'Chrono.tech is the fastest-growing Australian blockchain company. We believe that blockchain technology is the key to revolutionising global recruitment and payment processes.'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://chrono.tech/assets/img/landing-2.png'
        }
      ],
      script: [
        {
          vmid: 'Organization',
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Сhrono.tech',
            description: 'Blockchain HR Solutions. The leading Australian blockchain company',
            url: 'https://chrono.tech',
            logo: 'https://chrono.tech/assets/img/main/logo.svg'
          }
        }
      ]
    }
  },
  serverPrefetch () {
    return this.$store.dispatch('blog/loadLastPosts')
  },
  mounted () {
    if (!this.isDataLoaded) {
      this.$store.dispatch('blog/loadLastPosts')
    }
  },
  computed: {
    ...mapState({
      posts: state => state.blog.lastPosts
    }),
    isDataLoaded () {
      return this.posts.length
    }
  }
}
