export default [
  {
    type: 'tg',
    url: 'https://t.me/chronotech'
  },
  {
    type: 'fb',
    url: 'https://facebook.com/ChronotechNews/'
  },
  // {
  //   type: 'rd',
  //   url: 'https://www.reddit.com/r/ChronoBank/'
  // },
  {
    type: 'tw',
    url: 'https://twitter.com/ChronotechNews'
  },
  {
    type: 'in',
    url: 'https://www.linkedin.com/company/chronotechnews/'
  },
  // {
  //   type: 'md',
  //   url: 'https://blog.chrono.tech/'
  // },
  // {
  //   type: 'ds',
  //   url: 'https://discord.gg/tJu3wqM4XS'
  // },
  {
    type: 'yt',
    url: 'https://www.youtube.com/c/ChronoTech'
  }
]
