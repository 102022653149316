import { mapMutations } from 'vuex'
import headerPartial from 'src/partials/default/HeaderPartial/HeaderPartial.vue'
import footerPartial from 'src/partials/default/FooterPartial/FooterPartial.vue'
import pageNotFound from 'src/pages/default/404Page/PageNotFound.vue'

export default {
  head () {
    const meta = {
      titleTemplate: '%s | Chrono.tech'
    }
    if (Object.keys(this.$route.query).length) {
      meta.link = [{
        vmid: 'canonical',
        rel: 'canonical',
        href: 'https://chrono.tech' + this.$route.path
      }]
    }

    return meta
  },
  components: {
    pageNotFound,
    headerPartial,
    footerPartial
  },
  computed: {
    notFound () {
      return this.$store.state.app.notFound || this.$route.name === 'NotFound'
    }
  },
  watch: {
    $route: {
      handler () {
        if (this.notFound) {
          this.setNotFound(false)
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setNotFound: 'app/setNotFound'
    })
  }
}
