import * as directives from 'src/directives'
import { LaxMixinFactory } from 'src/mixins'

export default {
  props: {
    product: Object
  },
  directives: {
    ...directives
  },
  mixins: [
    LaxMixinFactory(function () { this.calcScale() })
  ],
  computed: {
    animations () {
      const start = this.startScrollOffset
      const end = this.endScrollOffset
      const array = this.product.animations.map(
        animation => ({
          ...animation,
          left: `${animation.position.x}px`,
          top: `${animation.position.y}px`,
          laxOpacity: `${start + animation.offset} ${animation.transition.source.opacity}, ${end + animation.offset} ${animation.transition.target.opacity}`,
          laxTranslateX: `${start + animation.offset} ${animation.transition.source.x}px, ${end + animation.offset} ${100 * animation.transition.target.x}px`,
          laxTranslateY: `${start + animation.offset} ${animation.transition.source.y}px, ${end + animation.offset} ${100 * animation.transition.target.y}px`
        })
      )
      return array
    }
  },
  data () {
    return {
      scaleX: null,
      scaleY: null,
      startScrollOffset: 0,
      endScrollOffset: 0
    }
  },
  methods: {
    calcScale () {
      const { top } = this.$el.getBoundingClientRect()
      const { scrollY } = window
      const { width: canvasWidth, height: canvasHeight } = this.$refs.canvas.getBoundingClientRect()
      this.scaleX = canvasWidth / this.product.imageWidth
      this.scaleY = canvasHeight / this.product.imageHeight
      this.endScrollOffset = Math.round(scrollY + top - window.innerHeight / 2)
      this.startScrollOffset = Math.round(scrollY + top - window.innerHeight)
    },
    onCanvasLoad () {
      this.calcScale()
    }
  }
}
