import { mapActions, mapState } from 'vuex'
import breadcrumb from 'src/components/default/Breadcrumb/Breadcrumb.vue'
import postCard from 'src/components/default/PostCard/PostCard.vue'
import { email, required } from 'vuelidate/lib/validators'

export default {
  components: {
    breadcrumb,
    postCard
  },
  data () {
    return {
      subDialog: false,
      error: null,
      loadingSub: false,
      subscribeSuccess: false,
      subForm: {
        email: ''
      },
      breadcrumbLinks: [
        {
          title: 'Home',
          to: '/'
        },
        {
          title: 'Blog'
        }
      ]
    }
  },
  validations: {
    subForm: {
      email: { required, email }
    }
  },
  serverPrefetch () {
    return this.$store.dispatch('blog/loadPosts')
  },
  mounted () {
    if (!this.isDataLoaded) {
      this.$store.dispatch('blog/loadPosts')
    }
  },
  computed: {
    ...mapState({
      posts: state => state.blog.posts
    })
  },
  methods: {
    ...mapActions({
      subscribe: 'blog/subscribe'
    }),
    async handleSubmit () {
      this.$v.subForm.$touch()
      if (this.$v.subForm.$error) {
        return
      }
      try {
        this.loadingSub = true
        this.error = null
        await this.subscribe({
          ...this.subForm
        })
        Object.assign(this.subForm, {
          email: ''
        })
        this.$v.subForm.$reset()
        this.subDialog = false
        this.loadingSub = false
        this.subscribeSuccess = true
      } catch (e) {
        this.error = e.message
      }
    }
  },
  head () {
    return {
      title: 'Blog'
    }
  }
}
