import {
  ContactUs,
  DefaultLayout,
  DefaultPage,
  PageNotFound,
  AboutPage,
  FaqPage,
  ProductPage,
  Blog,
  Post,
  Ecosystem,
  HowToBuyTimePage
} from 'src/pages'

export default () => [
  {
    path: '',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Landing',
        component: DefaultPage
      },
      {
        path: '/contact',
        name: 'Contact Us',
        component: ContactUs
      },
      {
        path: '/about',
        name: 'About Us',
        component: AboutPage
      },
      {
        path: '/faq',
        name: 'FAQ',
        component: FaqPage
      },
      {
        path: '/product/paymentx',
        name: 'PaymentX',
        component: ProductPage,
        props: {
          slug: 'paymentx'
        }
      },
      {
        path: '/product/laborx',
        name: 'LaborX',
        component: ProductPage,
        props: {
          slug: 'laborx'
        }
      },
      {
        path: '/product/timex',
        name: 'TimeX',
        component: ProductPage,
        props: {
          slug: 'timex'
        }
      },
      {
        path: '/product/audt',
        name: 'Audt',
        component: ProductPage,
        props: {
          slug: 'audt'
        }
      },
      {
        path: '/blog',
        name: 'Blog',
        component: Blog
      },
      {
        path: '/blog/:slug',
        name: 'Post',
        component: Post
      },
      {
        path: '/ecosystem',
        name: 'Ecosystem',
        component: Ecosystem
      },
      {
        path: '/how-to-buy-time',
        name: 'HowToBuyTimePage',
        component: HowToBuyTimePage
      },
      {
        path: '*',
        component: PageNotFound,
        name: 'NotFound'
      }
    ]
  }
]
