import { products } from 'src/data/products'
import featuresPartial from 'src/partials/default/FeaturesPartial/FeaturesPartial.vue'
import pageSocialPartial from 'src/partials/default/PageSocialPartial/PageSocialPartial.vue'

export default {
  components: {
    featuresPartial,
    pageSocialPartial
  },
  props: {
    slug: String
  },
  computed: {
    product () {
      return products[this.slug]
    }
  },
  head () {
    return {
      title: this.product.meta.title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.product.meta.description
        }
      ],
      script: this.product.meta.scripts
    }
  }
}
