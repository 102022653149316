import * as directives from 'src/directives'

export default {
  directives: {
    ...directives
  },
  data () {
    return {
      keys: [
        {
          name: 'Kucoin',
          link: 'https://trade.kucoin.com/TIME-BTC',
          img: '/assets/img/kucoin.svg'
        },
        {
          name: 'Coinbase',
          link: 'https://pro.coinbase.com/trade/TIME-USD',
          img: '/assets/img/coinbase.svg'
        },
        {
          name: 'Bybit',
          link: 'https://www.bybit.com/en-US/trade/spot/TIME/USDT',
          img: '/assets/img/bybit.svg'
        },
        {
          name: 'Bitget',
          link: 'https://www.bitget.com/en/spot/timeusdt_SPBL',
          img: '/assets/img/bitget.svg'
        },
        {
          name: 'Gate.io',
          link: 'https://www.gate.io/trade/TIMECHRONO_USDT',
          img: '/assets/img/gateio.svg'
        },
        {
          name: 'TimeX',
          link: 'https://timex.io/buy-time',
          img: '/assets/img/timex.svg'
        }
      ]
    }
  }
}
