import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      isFrontend: false
    }
  },
  computed: {
    ...mapGetters({
      isCookieMessageVisible: 'storage/isCookieMessageVisible'
    })
  },
  methods: {
    ...mapActions({
      cookieStateToggle: 'storage/cookieStateToggle'
    })
  },
  mounted () {
    this.isFrontend = true
  }
}
