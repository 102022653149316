export default {
  head () {
    return {
      title: '404 Page'
    }
  },
  asyncData ({ error }) {
    error(new Error('Page not found'), 404)
  }
}
