import faqPartial from 'src/partials/default/FaqPartial/FaqPartial.vue'

export default {
  components: {
    faqPartial
  },
  head () {
    return {
      title: 'FAQ'
    }
  }
}
