import { get } from 'lodash'
import { LX_BACKEND } from 'src/remotes'

export default () => ({
  namespaced: true,
  state: () => ({
    timePrice: null,
    timeDif: null
  }),
  mutations: {
    setTimePrice (state, price) {
      state.timePrice = price
    },
    setTimeDif (state, dif) {
      state.timeDif = dif
    }
  },
  actions: {
    async loadRate ({ commit }) {
      try {
        const { data } = await LX_BACKEND.get('rates/range24h', {
          params: { currency: 'TIME' }
        })
        commit('setTimePrice', get(data, 'result.0.price'))
        commit('setTimeDif', get(data, 'result.0.percent_diff'))
      } catch (e) {
        console.error(e)
      }
    }
  }
})
