export const COOKIE_STATE_TOGGLE = 'cookieStateToggle'

export default () => ({
  namespaced: true,
  state: () => ({
    isCookieMessageVisible: true,
    newTimeShowed: false
  }),
  getters: {
    isCookieMessageVisible: state => state.isCookieMessageVisible
  },
  mutations: {
    [COOKIE_STATE_TOGGLE] (state) {
      state.isCookieMessageVisible = !state.isCookieMessageVisible
    },
    setNewTimeShowed (state, value) {
      state.newTimeShowed = value
    }
  },
  actions: {
    async cookieStateToggle ({ commit }) {
      commit(COOKIE_STATE_TOGGLE)
    }
  }
})
