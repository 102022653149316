import { BACKEND, LX_BACKEND } from 'src/remotes'

export default () => ({
  namespaced: true,
  state: () => ({
    lastPosts: [],
    posts: [],
    postData: []
  }),
  getters: {
    post: state => index => state.postData[index] || null
  },
  mutations: {
    setLastPosts (state, posts) {
      state.lastPosts = posts
    },
    setPosts (state, posts) {
      state.posts = posts
    },
    setPostData (state, { slug, post }) {
      state.postData = { ...state.postData, [slug]: post }
    }
  },
  actions: {
    async loadLastPosts ({ commit, state }) {
      try {
        if (state.lastPosts && state.lastPosts.length === 0) {
          const { data } = await LX_BACKEND.get('blog/list', {
            params: {
              limit: 3,
              siteId: 'chrono.tech'
            }
          })
          commit('setLastPosts', data.result.blog)
        }
      } catch (e) {
        commit('setLastPosts', [])
      }
    },
    async loadPosts ({ commit, state }) {
      try {
        if (state.posts && state.posts.length === 0) {
          const { data } = await LX_BACKEND.get('blog/list', {
            params: {
              limit: 24,
              siteId: 'chrono.tech'
            }
          })
          commit('setPosts', data.result.blog)
        }
      } catch (e) {
        commit('setPosts', [])
      }
    },
    async getPost ({ commit }, slug) {
      try {
        const { data } = await LX_BACKEND.get('blog/get', {
          params: { url: slug, siteId: 'chrono.tech' }
        })
        commit('setPostData', { slug, post: data.result })
        return true
      } catch (e) {
        commit('setPostData', { slug, post: null })
        return false
      }
    },
    async subscribe (_, { email }) {
      await BACKEND.post('blog/articles/subscribe', { email })
    }
  }
})
