import * as directives from 'src/directives'
import socialLinks from 'src/components/default/SocialLinks/SocialLinks.vue'

export default {
  props: {
    productName: String,
    link: String,
    socials: Array
  },
  components: {
    socialLinks
  },
  directives: {
    ...directives
  }
}
