import { uniqueId } from 'lodash'

export default () => ({
  namespaced: true,
  state: () => ({
    notFound: false,
    modalStack: []
  }),
  getters: {
    getIsOpenedModal (state) {
      return state.modalStack.length > 0
    }
  },
  mutations: {
    setNotFound (state, value) {
      state.notFound = value
    },
    addToModalStack: (state, modalData) => {
      state.modalStack.push(modalData)
    },
    removeFromModalStack: (state, id) => {
      if (id) {
        const index = state.modalStack.findIndex(modal => modal.id === id)
        if (index !== -1) {
          state.modalStack.splice(index, 1)
        }
      } else {
        state.modalStack.pop()
      }
    }
  },
  actions: {
    resetState ({ commit }) {
      commit('resetState')
    },
    openModal: ({ state, commit }, modalData) => {
      if (modalData.id && state.modalStack.find(modal => modal.id === modalData.id)) {
        return modalData.id
      }
      const id = modalData.id || uniqueId('modal_')
      commit('addToModalStack', { id, ...modalData })
      return id
    },
    closeModal: ({ commit }, id) => {
      commit('removeFromModalStack', typeof id === 'string' ? id : null)
    }
  }
})
