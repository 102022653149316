import storySection from 'src/components/default/StorySection/StorySection.vue'

export default {
  components: {
    storySection
  },
  data () {
    return {
      stories: [
        {
          title: 'Mission',
          brief: 'Chrono.tech was founded in 2016 with the aim of making short-term employment opportunities as easy to access as any other online service. The company empowers HR and recruitment professionals with blockchain technology, as well as enabling global freelancers to secure the best jobs and make sure they are paid quickly and fairly.',
          stereotype: 'LTR',
          image: '/assets/img/about-us/mission.png'
        },
        {
          title: 'Vision',
          brief: 'Chrono.tech vision is for a world in which anyone can find work and businesses can find the people they need, circumventing the middlemen, costs and inefficiencies of the conventional recruitment sector that so often proves a barrier to employment. The company’s wide-ranging ecosystem is designed to transform the way individuals access jobs and businesses connect with contractors, regardless of where workers and employers are located.',
          stereotype: 'RTL',
          image: '/assets/img/about-us/vision.png'
        },
        {
          title: 'History',
          brief: 'The Sydney-based company was launched after a successful token sale in 2016. Since then Chrono.tech has built a suite of tools and services to facilitate recruitment, invoicing and payments, with a labour-hire platform LaborX, Plasma-based exchange TimeX, and cryptocurrency payroll solution PaymentX to reduce costs and improve quality and reliability.',
          stereotype: 'LTR',
          image: '/assets/img/about-us/history.png'
        }
      ]
    }
  }
}
