import { gaSendEvent } from 'src/utils/analytic'

export default {
  props: {
    product: String,
    socials: Array
  },
  methods: {
    getSocialNameByType (type) {
      switch (type) {
        case 'tg':
          return 'Telegram'
        case 'fb':
          return 'Facebook'
        case 'rd':
          return 'Reddit'
        case 'tw':
          return 'Twitter'
        case 'in':
          return 'LinkedIn'
        case 'md':
          return 'Medium'
        case 'yt':
          return 'Youtube'
        case 'ds':
          return 'Discord'
      }
    },
    onSocialClick (type) {
      gaSendEvent('Social_Click', this.product, this.getSocialNameByType(type))
    }
  }
}
