import faqBlock from 'src/components/default/FaqBlock/FaqBlock.vue'
import * as directives from 'src/directives'

export default {
  components: {
    faqBlock
  },
  directives: {
    ...directives
  },
  data () {
    return {
      faqList: [
        {
          title: 'What is TimeWarp?',
          brief: '<a href="https://timewarp.finance/" target="_blank">TimeWarp</a> is a new rewards programme for holders of TIME, the native token of the Chrono.tech ecosystem. By locking their TIME tokens in a smart contract, users will receive rewards in the form of additional TIME.'
        },
        {
          title: 'What is LaborX?',
          brief: 'LaborX is an efficient and secure blockchain-based <a href="https://laborx.com" target="_blank">web3 jobs platform</a> for creating contracts between a Client and Service Provider'
        },
        {
          title: 'What is TimeX?',
          brief: '<a href="https://timex.io/" target="_blank">TimeX</a>, a Plasma-based hybrid cryptocurrency exchange, combines the speed and privacy of a centralised system with the security and transparency of a decentralised solution.'
        },
        {
          title: 'What is PaymentX?',
          brief: '<a href="https://paymentx.io/" target="_blank">PaymentX</a> is an automated cryptocurrency payroll solution for your business. Pay the team using crypto in one click, send and receive professional invoices, set up regular payment dates - all quickly and conveniently!'
        },
        {
          title: 'What is AUDT?',
          brief: '<a href="https://audt.to/" target="_blank">AUD Token (AUDT)</a> is a stablecoin linked to Australian dollar, which takes the form of an ERC20-compliant token on the Ethereum network, held in a licensed bank account.'
        }
      ]
    }
  },
  head () {
    return {
      script: [
        {
          vmid: 'FAQPage',
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: this.faqList.map(question => ({
              '@type': 'Question',
              name: question.title,
              acceptedAnswer: {
                '@type': 'Answer',
                text: question.brief
              }
            }))
          }
        }
      ]
    }
  }
}
