import createPersistedState from 'vuex-persistedstate'

export default async ({ store }) => {
  if (process.client) {
    createPersistedState({
      key: 'persistedSessionState',
      storage: window.localStorage,
      paths: [
        'storage'
      ]
    })(store)
  }
}
