import faqPartial from 'src/partials/default/FaqPartial/FaqPartial.vue'
import communityPartial from 'src/partials/default/CommunityPartial/CommunityPartial.vue'
import TokenWidgetWidePartial from 'src/partials/default/TokenWidgetWidePartial/TokenWidgetWidePartial.vue'

export default {
  components: {
    faqPartial,
    communityPartial,
    TokenWidgetWidePartial
  },
  data () {
    return {
      startFixedPosition: 0,
      endFixedPosition: 0,
      hoverOffset: 0
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      if (window.innerWidth > 1150) {
        this.startFixedPosition = 65
        this.endFixedPosition = 2245
        this.hoverOffset = 400
      } else if (window.innerWidth > 960) {
        this.startFixedPosition = 65
        this.endFixedPosition = 1865
        this.hoverOffset = 400
      } else if (window.innerWidth > 860) {
        this.startFixedPosition = 65
        this.endFixedPosition = 1755
        this.hoverOffset = 400
      } else if (window.innerWidth > 800) {
        this.startFixedPosition = 65
        this.endFixedPosition = 1700
        this.hoverOffset = 400
      } else if (window.innerWidth > 599) {
        this.hoverOffset = 300
        this.startFixedPosition = 273
        this.endFixedPosition = 923
      } else {
        this.hoverOffset = 300
        this.startFixedPosition = 250
        this.endFixedPosition = 9999
      }
      this.$refs.city.style.top = null
      this.$refs.city.style.position = null
      this.$refs.city.classList.remove('fill')
      this.onScroll()
    },
    onScroll () {
      if (window.innerWidth <= 800) {
        if (window.scrollY < 464) {
          this.$refs.city.classList.remove('fill')
        } else {
          this.$refs.city.classList.add('fill')
        }
      }

      if (window.scrollY < this.startFixedPosition) {
        this.$refs.city.classList.remove('fixed-end')
        this.$refs.city.classList.remove('fixed')
      } else if (window.scrollY < this.endFixedPosition) {
        this.$refs.city.classList.add('fixed')
        this.$refs.city.classList.remove('fixed-end')
      } else {
        this.$refs.city.classList.add('fixed-end')
        this.$refs.city.classList.remove('fixed')
      }

      let scrollTop = window.scrollY + this.hoverOffset

      if (window.innerWidth < 600) {
        if (scrollTop - 20 > this.$refs.px.offsetTop) {
          this.$refs.city.style.top = `${this.$refs.px.offsetTop - 431}px`
          this.$refs.city.style.position = 'absolute'
        } else {
          this.$refs.city.style.top = null
          this.$refs.city.style.position = null
        }
      }

      if (scrollTop > this.$refs.px.offsetTop) {
        this.$refs.city.classList.add('px')
        this.$refs.city.classList.remove('lx')
        this.$refs.city.classList.remove('tx')
      } else if (scrollTop > this.$refs.tx.offsetTop) {
        this.$refs.city.classList.add('tx')
        this.$refs.city.classList.remove('lx')
        this.$refs.city.classList.remove('px')
      } else if (scrollTop > this.$refs.lx.offsetTop) {
        this.$refs.city.classList.add('lx')
        this.$refs.city.classList.remove('px')
        this.$refs.city.classList.remove('tx')
      } else {
        this.$refs.city.classList.remove('lx')
        this.$refs.city.classList.remove('px')
        this.$refs.city.classList.remove('tx')
      }
    },
    onClickLearnMore () {
      const element = document.getElementsByClassName('margin-block')[0]
      window.scrollTo({
        top: element.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  head () {
    return {
      title: 'Chrono Ecosystem',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Using blockchain to streamline global access to work and payments'
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Chrono Ecosystem'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Using blockchain to streamline global access to work and payments'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://chrono.tech/assets/img/ecosystem-preview.png'
        }
      ]
    }
  }
}
