import Swiper from 'swiper'
import postCard from 'src/components/default/PostCard/PostCard.vue'
import * as directives from 'src/directives'

export default {
  components: {
    postCard
  },
  directives: {
    ...directives
  },
  props: {
    posts: Array
  },
  mounted () {
    // eslint-disable-next-line
    new Swiper(this.$refs.swiper, {
      slidesPerView: 'auto',
      spaceBetween: 30
      // centeredSlides: true
    })
  }
}
