import * as directives from 'src/directives'
import { productsSorted } from 'src/data/products'
import socials from 'src/data/socials'
import socialLinks from 'src/components/default/SocialLinks/SocialLinks.vue'

export default {
  components: {
    socialLinks
  },
  directives: {
    ...directives
  },
  data () {
    return {
      isActive: false,
      isShown: false,
      disableScroll: false,
      headerProductsToggle: false,
      products: productsSorted,
      socials
    }
  },
  watch: {
    disableScroll (val) {
      if (val) {
        document.querySelector('html').classList.add('disabled-scroll')
      } else {
        document.querySelector('html').classList.remove('disabled-scroll')
      }
    }
  },
  methods: {
    showHideMenu: function () {
      if (!this.isActive) {
        this.disableScroll = true
        this.isActive = true
      } else {
        this.disableScroll = false
        this.isShown = true
        setTimeout(() => {
          this.isShown = false
          this.isActive = false
        }, 400)
      }
    },
    hideMenu: function () {
      this.disableScroll = false
      this.isShown = true
      setTimeout(() => {
        this.isShown = false
        this.isActive = false
      }, 400)
    },
    onClickLogo () {
      if (this.$route.name === 'Landing') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }
}
