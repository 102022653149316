export const paymentx = {
  id: 'paymentx',
  name: 'PaymentX',
  description: 'Manage your cryptocurrency payroll seamlessly',
  website: 'https://paymentx.io',
  featuresDescription: 'PaymentX enables you to pay employees around the world quickly and conveniently using crypto',
  productImg: '/assets/img/products/px/product.svg',
  logo: '/assets/img/products/px/logo.svg',
  features: [
    {
      name: 'Send and receive crypto invoices',
      img: '/assets/img/products/px/feature-1.svg'
    },
    {
      name: 'Run payroll securely and seamlessly',
      img: '/assets/img/products/px/feature-2.svg'
    },
    {
      name: 'Pay multiple invoices in one click',
      img: '/assets/img/products/px/feature-3.svg'
    }
  ],
  socials: [
    {
      type: 'tw',
      url: 'https://twitter.com/Payment_X'
    },
    {
      type: 'fb',
      url: 'https://www.facebook.com/paymentx/'
    },
    {
      type: 'md',
      url: 'https://medium.com/paymentx'
    },
    {
      type: 'tg',
      url: 'https://t.me/paymentx'
    },
    {
      type: 'in',
      url: 'https://www.linkedin.com/company/paymentx/'
    }
  ],
  meta: {
    title: 'PaymentX - Cryptocurrency Payroll Accounting Software',
    description: 'Manage your cryptocurrency payroll seamlessly',
    scripts: [
      {
        vmid: 'productData',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'product',
          brand: 'Chrono.Tech',
          logo: 'https://chrono.tech/assets/img/products/px/logo.svg',
          name: 'PaymentX',
          category: 'Payments',
          image: 'https://chrono.tech/assets/img/products/px/logo.svg',
          description: 'Manage your cryptocurrency payroll seamlessly',
          aggregateRating: {
            '@type': 'aggregateRating',
            ratingValue: '5',
            reviewCount: '21'
          }
        }
      }
    ]
  },
  text: 'PaymentX is an automated cryptocurrency payroll solution for your business. Pay the team using crypto in one click, send and receive professional invoices, set up regular payment dates - all quickly and conveniently!',
  stereotype: 'LTR',
  imageWidth: 588,
  imageHeight: 534,
  animationImg: '/assets/img/products/px/product-2.svg',
  animations: [
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: -88,
          x: 44
        }
      },
      position: {
        y: 92,
        x: 96
      },
      offset: 0,
      icon: '/assets/img/products/px/animation/1.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 65,
          x: -72
        }
      },
      position: {
        y: 105,
        x: 85
      },
      offset: 20,
      icon: '/assets/img/products/px/animation/2.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: -70,
          x: 161
        }
      },
      position: {
        y: 131,
        x: 146
      },
      offset: 100,
      icon: '/assets/img/products/px/animation/3.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: -51,
          x: -152
        }
      },
      position: {
        y: 134,
        x: 133
      },
      offset: 120,
      icon: '/assets/img/products/px/animation/4.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 111,
          x: 14
        }
      },
      position: {
        y: 114,
        x: 99
      },
      offset: 60,
      icon: '/assets/img/products/px/animation/5.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: -118,
          x: 156
        }
      },
      position: {
        y: 105,
        x: 113
      },
      offset: 40,
      icon: '/assets/img/products/px/animation/6.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 0,
          x: 0
        }
      },
      position: {
        y: 119,
        x: 120
      },
      offset: 80,
      icon: '/assets/img/products/px/animation/7.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 5,
          x: 125
        }
      },
      position: {
        y: 28,
        x: 371
      },
      offset: 140,
      icon: '/assets/img/products/px/animation/8.svg'
    }
  ]
}

export const laborx = {
  id: 'laborx',
  name: 'LaborX',
  description: 'Blockchain-secured platform for on-demand employment',
  website: 'https://laborx.com',
  featuresDescription: 'LaborX helps clients and freelancers connect, providing efficient blockchain transactions and robust protections through smart contracts — wherever you\'re based',
  productImg: '/assets/img/products/lx/product.svg',
  logo: '/assets/img/products/lx/logo.svg',
  features: [
    {
      name: 'Reputation',
      description: 'The Reputation module provides protections and a feedback system to help users select the best freelancers and clients',
      img: '/assets/img/products/lx/feature-1.svg'
    },
    {
      name: 'Smart Contract',
      description: 'Set the conditions for how you\'ll work together, using the Contract module. Protect your financial relationship through digital escrow by locking the funds when the contract is signed',
      img: '/assets/img/products/lx/feature-2.svg'
    },
    {
      name: 'Dispute Service',
      description: 'Disputes can arise when each side disagrees about the quality of the work. Should this occur, users can employ the Dispute service. You can suggest a third-party mediator, or LaborX can provide one',
      img: '/assets/img/products/lx/feature-3.svg'
    }
  ],
  socials: [
    {
      type: 'tw',
      url: 'https://twitter.com/LaborXNews'
    },
    {
      type: 'fb',
      url: 'https://www.facebook.com/LaborXNews'
    },
    {
      type: 'tg',
      url: 'https://t.me/laborx'
    },
    {
      type: 'in',
      url: 'https://www.linkedin.com/company/laborxnews'
    }
  ],
  meta: {
    title: 'LaborX - Freelance Crypto jobs',
    description: 'Efficient and secure blockchain-based solutions for employers and jobseekers',
    scripts: [
      {
        vmid: 'productData',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'product',
          brand: 'Chrono.Tech',
          logo: 'https://chrono.tech/assets/img/products/lx/logo.svg',
          name: 'LaborX',
          category: 'HR',
          image: 'https://chrono.tech/assets/img/products/lx/logo.svg',
          description: 'Efficient and secure blockchain-based solutions for employers and jobseekers'
        }
      }
    ]
  },
  text: 'LaborX is a global recruitment platform that connects individuals with work opportunities, wherever they are based in the world, and enables them to get paid in crypto. The software offers efficient transactions and robust protections for both parties.',
  stereotype: 'LTR',
  imageWidth: 598,
  imageHeight: 525,
  animationImg: '/assets/img/products/lx/product-2.svg',
  animations: [
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 0,
          y: 40,
          x: 0
        }
      },
      position: {
        y: 120,
        x: 225
      },
      offset: 200,
      icon: '/assets/img/products/lx/animation/1.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 0,
          y: 40,
          x: 0
        }
      },
      position: {
        y: 190,
        x: 235
      },
      offset: 200,
      icon: '/assets/img/products/lx/animation/2.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 0,
          y: 40,
          x: 0
        }
      },
      position: {
        y: 5,
        x: 295
      },
      offset: 100,
      icon: '/assets/img/products/lx/animation/3.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 0,
          y: 40,
          x: 0
        }
      },
      position: {
        y: 20,
        x: 170
      },
      offset: 100,
      icon: '/assets/img/products/lx/animation/4.svg'
    }
  ]
}

export const audt = {
  id: 'audt',
  name: 'AUDT',
  description: 'Stablecoin linked to Australian dollar',
  website: 'https://audt.to',
  featuresDescription: 'The stability and trust of traditional currency with the transparency and freedom of cryptocurrency',
  productImg: '/assets/img/products/audt/product.svg',
  logo: '/assets/img/products/audt/logo.svg',
  features: [
    {
      name: 'Hedge against volatility',
      description: 'Access to a stablecoin in your local currency (AUD)',
      img: '/assets/img/products/audt/feature-1.svg'
    },
    {
      name: 'Global transfers of fixed value',
      description: 'E-commerce payments and remittance rely on the value sent being the value received',
      img: '/assets/img/products/audt/feature-2.svg'
    },
    {
      name: 'Connect with real-world commerce',
      description: 'Integrate real-world currencies or goods with crypto, with dramatically reduced risk',
      img: '/assets/img/products/audt/feature-3.svg'
    },
    {
      name: 'Interact with Australian traders',
      description: 'Work with other traders in a common currency, without the need for fiat money',
      img: '/assets/img/products/audt/feature-4.svg'
    },
    {
      name: 'Enhanced trading strategies',
      description: 'Cross-exchange and multi-currency arbitrage opportunities',
      img: '/assets/img/products/audt/feature-5.svg'
    },
    {
      name: 'And much more',
      description: 'Our team is working hard to improve AUDT every day',
      img: '/assets/img/products/audt/feature-6.svg'
    }
  ],
  socials: [
    {
      type: 'tw',
      url: 'https://twitter.com/audt_to'
    },
    {
      type: 'fb',
      url: 'https://fb.me/AUDToken'
    },
    {
      type: 'tg',
      url: 'https://t.me/audt_to'
    }
  ],
  meta: {
    title: 'AUDT - Stablecoin linked to Australian dollar',
    description: 'Stablecoin linked to Australian dollar',
    scripts: [
      {
        vmid: 'productData',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'product',
          brand: 'Chrono.Tech',
          logo: 'https://chrono.tech/assets/img/products/audt/product.svg',
          name: 'AUDT',
          category: 'Trading',
          image: 'https://chrono.tech/assets/img/products/audt/product.svg',
          description: 'Stablecoin linked to Australian dollar'
        }
      }
    ]
  },
  text: 'AUDT is a fiat-backed stablecoin, underpinned with a 100% reserve of Australian dollars – meaning that each AUDT can be redeemed for real Australian dollars on a 1:1 basis at any time.',
  stereotype: 'RTL',
  imageWidth: 494,
  imageHeight: 646,
  animationImg: '/assets/img/products/audt/product-2.svg',
  animations: [
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 20,
          x: -115
        }
      },
      position: {
        y: 113,
        x: 251
      },
      offset: 140,
      icon: '/assets/img/products/audt/animation/1.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 10,
          x: 155
        }
      },
      position: {
        y: 71,
        x: 258
      },
      offset: 100,
      icon: '/assets/img/products/audt/animation/2.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 40,
          x: -3
        }
      },
      position: {
        y: 75,
        x: 48
      },
      offset: 0,
      icon: '/assets/img/products/audt/animation/3.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 40,
          x: -140
        }
      },
      position: {
        y: 82,
        x: 113
      },
      offset: 20,
      icon: '/assets/img/products/audt/animation/4.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: -90,
          x: -100
        }
      },
      position: {
        y: 65,
        x: 238
      },
      offset: 90,
      icon: '/assets/img/products/audt/animation/5.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 50,
          x: 65
        }
      },
      position: {
        y: 97,
        x: 121
      },
      offset: 40,
      icon: '/assets/img/products/audt/animation/6.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: -60,
          x: 130
        }
      },
      position: {
        y: 44,
        x: 254
      },
      offset: 80,
      icon: '/assets/img/products/audt/animation/7.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: -95,
          x: 65
        }
      },
      position: {
        y: 44,
        x: 235
      },
      offset: 60,
      icon: '/assets/img/products/audt/animation/8.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 55,
          x: 135
        }
      },
      position: {
        y: 122,
        x: 267
      },
      offset: 160,
      icon: '/assets/img/products/audt/animation/9.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 140,
          x: -95
        }
      },
      position: {
        y: 87,
        x: 250
      },
      offset: 120,
      icon: '/assets/img/products/audt/animation/10.svg'
    }
  ]
}

export const timex = {
  id: 'timex',
  name: 'TimeX',
  description: 'Plasma-based exchange. The platform combines both centralised and decentralised technologies',
  website: 'https://timex.io',
  featuresDescription: 'A Plasma-based hybrid cryptocurrency exchange, combines the speed and privacy of a centralised system with the security and transparency of a decentralised solution. Buy and sell crypto safe',
  productImg: '/assets/img/products/tx/product.png',
  logo: '/assets/img/products/tx/logo.svg',
  features: [
    {
      name: 'API integration',
      description: 'Our powerful API offers an effective way to implement algorithmic trading strategies, conveniently and securely',
      img: '/assets/img/products/tx/feature-1.svg'
    },
    {
      name: 'Fiat friendly',
      description: 'Deposit AUD directly from your bank account or credit card at any time',
      img: '/assets/img/products/tx/feature-2.svg'
    },
    {
      name: 'Integration with terminals',
      description: 'Access TimeX easily with a smartphone and other mobile devices. No more lost trades',
      img: '/assets/img/products/tx/feature-3.svg'
    },
    {
      name: 'Cash back for traders',
      description: 'Taker fee: 0,5%. Maker fee: 0,25%. Fees go back for referral program participants',
      img: '/assets/img/products/tx/feature-4.svg'
    }
  ],
  socials: [
    {
      type: 'tw',
      url: 'https://twitter.com/ExchangeTimex'
    },
    {
      type: 'fb',
      url: 'https://www.facebook.com/timex.io'
    },
    {
      type: 'rd',
      url: 'https://www.reddit.com/r/ChronoBank/'
    },
    {
      type: 'tg',
      url: 'https://t.me/timexio'
    },
    {
      type: 'in',
      url: 'https://www.linkedin.com/company/timex-io/'
    },
    {
      type: 'yt',
      url: 'https://www.youtube.com/channel/UCpC4c8JCWoX2GA0vGpUTK2w'
    }
  ],
  meta: {
    title: 'TimeX - Australian Bitcoin Exchange',
    description: 'Plasma-based exchange. The platform combines both centralised and decentralised technologies',
    scripts: [
      {
        vmid: 'productData',
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@type': 'product',
          brand: 'Chrono.Tech',
          logo: 'https://chrono.tech/assets/img/products/tx/product.svg',
          name: 'TimeX',
          category: 'Trading',
          image: 'https://chrono.tech/assets/img/products/tx/product.svg',
          description: 'Plasma-based exchange. The platform combines both centralised and decentralised technologies'
        }
      }
    ]
  },
  text: 'TimeX is a Plasma-based exchange. The platform combines both centralised and decentralised technologies, offering the best of both worlds in terms of speed, security, cost and protection against front-running and illegal activities.',
  stereotype: 'RTL',
  imageWidth: 524,
  imageHeight: 610,
  animationImg: '/assets/img/products/tx/product-2.svg',
  animations: [
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: -55,
          x: 90
        }
      },
      position: {
        y: 3,
        x: 190
      },
      offset: 0,
      icon: '/assets/img/products/tx/animation/1.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 40,
          x: -140
        }
      },
      position: {
        y: 30,
        x: 115
      },
      offset: 20,
      icon: '/assets/img/products/tx/animation/2.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 35,
          x: 133
        }
      },
      position: {
        y: 35,
        x: 240
      },
      offset: 60,
      icon: '/assets/img/products/tx/animation/3.svg'
    },
    {
      transition: {
        target: {
          opacity: 1,
          y: 0,
          x: 0
        },
        source: {
          opacity: 1,
          y: 65,
          x: 105
        }
      },
      position: {
        y: 60,
        x: 192
      },
      offset: 40,
      icon: '/assets/img/products/tx/animation/4.svg'
    }
  ]
}

export const timewarp = {
  id: 'timewarp',
  name: 'TimeWarp',
  description: 'Platform for staking TIME and earning ongoing rewards',
  website: 'https://timewarp.finance/',
  logo: '/assets/img/products/tw/logo.png'
}

export const products = {
  paymentx,
  laborx,
  audt,
  timex
}

export const productsSorted = {
  laborx,
  timex,
  paymentx,
  audt
}
