import { mapGetters, mapActions } from 'vuex'
import telegramPartial from 'src/partials/default/TelegramPartial/TelegramPartial.vue'
import { required, email } from 'vuelidate/lib/validators'
import VueRecaptcha from 'vue-recaptcha'

export default {
  components: {
    telegramPartial,
    VueRecaptcha
  },
  data () {
    return {
      mailForm: {
        name: '',
        phone: '',
        email: '',
        message: ''
      },
      error: null,
      isBusy: false,
      sendStatus: null
    }
  },
  validations: {
    mailForm: {
      name: { required },
      phone: { },
      email: { required, email },
      message: { required }
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/config'
    }),
    captchaSiteKey () {
      return this.config.captcha.captchaSiteKey
    }
  },
  methods: {
    ...mapActions({
      postEnquiry: 'collaboration/postEnquiry'
    }),
    handleSubmit () {
      this.$v.mailForm.$touch()
      if (this.$v.mailForm.$error) {
        return
      }
      this.$refs.recaptcha.execute()
    },
    handleExpired () {
      this.$refs.recaptcha.reset()
    },
    async handlePost (recaptchaToken) {
      this.$refs.recaptcha.reset()
      this.isBusy = true
      this.error = null
      setImmediate(async () => {
        try {
          this.error = null
          this.sendStatus = await this.postEnquiry({
            ...this.mailForm,
            recaptchaToken
          })
          Object.assign(this.mailForm, {
            name: '',
            phone: '',
            email: '',
            message: ''
          })
          this.$v.mailForm.$reset()
        } catch (e) {
          this.error = e.message
        } finally {
          this.isBusy = false
        }
      })
    }
  },
  head () {
    return {
      title: 'Contact Us',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Get in Touch with our team'
        }
      ],
      script: [
        { src: 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit', async: true, defer: true }
      ]
    }
  }
}
