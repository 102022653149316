import { timewarp, laborx, timex, paymentx, audt } from 'src/data/products'

export default {
  data () {
    return {
      date: new Date(),
      products: [timewarp, laborx, timex, paymentx, audt, { name: 'Time Bridge', website: 'https://timebridge.org/' }]
    }
  },
  methods: {
    onClickLogo () {
      if (this.$route.name === 'Landing') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }
}
