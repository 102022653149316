export default {
  props: {
    brief: String,
    size: {
      type: Boolean,
      default () {
        return false
      }
    },
    features: Array
  }
}
