import { mapActions, mapState } from 'vuex'
import { gaSendEvent } from 'src/utils/analytic'
import skeleton from 'src/components/default/Skeleton/Skeleton.vue'

export default {
  components: {
    skeleton
  },
  mounted () {
    this.loadRate()
  },
  computed: {
    ...mapState({
      timePrice: state => state.market.timePrice,
      timeDif: state => state.market.timeDif
    }),
    priceFormatted () {
      if (this.timePrice === null) {
        return 0
      }
      return parseFloat(this.timePrice).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    difFormatted () {
      if (this.timeDif === null) {
        return 0
      }
      return parseFloat(this.timeDif).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    marketCapFormatted () {
      if (this.timePrice === null) {
        return 0
      }
      return Math.round(this.timePrice * 710113).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  methods: {
    ...mapActions({
      loadRate: 'market/loadRate'
    }),
    onExchangeClick (exchange) {
      gaSendEvent('TIME_Widget', exchange)
    }
  }
}
