import * as directives from 'src/directives'
import socials from 'src/data/socials'
import socialLinks from 'src/components/default/SocialLinks/SocialLinks.vue'

export default {
  components: {
    socialLinks
  },
  directives: {
    ...directives
  },
  data () {
    return {
      socials
    }
  }
}
