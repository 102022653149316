export default {
  data () {
    return {
      members: [
        {
          name: 'Sergei Sergienko',
          position: 'Chief Executive Officer',
          avatar1: '/assets/img/about-us/sergei-sergienko-1.png',
          avatar2: '/assets/img/about-us/sergei-sergienko-2.svg'
        },
        {
          name: 'Andrey Petrushko',
          position: 'Chief Operating Officer',
          avatar1: '/assets/img/about-us/andrei-petrushko-1.png',
          avatar2: '/assets/img/about-us/andrei-petrushko-2.svg'
        }
        //{
         // name: 'Saypulla Sheykhanov',
         // position: 'Chief Technical Officer',
         // avatar1: '/assets/img/about-us/sayp1.png',
        //  avatar2: '/assets/img/about-us/sayp1.png'
       //  }
        // {
        //   name: 'Mikhail Savchenko',
        //   position: 'Chief Technical Officer',
        //   avatar1: '/assets/img/about-us/mihail-savchenko-1.png',
        //   avatar2: '/assets/img/about-us/mihail-savchenko-2.svg'
        // }
      ]
    }
  }
}
