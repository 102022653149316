import { get } from 'lodash'

export default {
  data () {
    return {
      refreshInterval: null
    }
  },
  mounted () {
    if (!document.getElementById('addthis')) {
      this.loadAddThis()
    } else {
      this.refreshInterval = setInterval(() => {
        if (get(window, 'addthis.layers.refresh')) {
          clearInterval(this.refreshInterval)
          this.refreshInterval = null
          window.addthis.layers.refresh()
        }
      }, 50)
    }
  },
  beforeDestroy () {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  },
  methods: {
    loadAddThis () {
      let script = document.createElement('script')
      script.id = 'addthis'
      script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f297a130c5b32a5'
      document.head.appendChild(script)
      window.addthis_config = window.addthis_config || {}
      window.addthis_config.lang = 'en'
    }
  }
}
