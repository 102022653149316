import * as directives from 'src/directives'
import TokenWidgetPartial from 'src/partials/default/TokenWidgetPartial/TokenWidgetPartial.vue'
import cookiesPartial from 'src/partials/default/CookiesPartial/CookiesPartial.vue'
import { productsSorted, timewarp } from 'src/data/products'

export default {
  directives: {
    ...directives
  },
  components: {
    TokenWidgetPartial,
    cookiesPartial
  },
  data () {
    return {
      active: null,
      blurTimeout: null,
      products: { ...productsSorted, timewarp },
      info: [
        {
          icon: '/assets/img/info/token.svg',
          name: 'TIME',
          text: 'Token'
        },
        {
          icon: '/assets/img/info/date.svg',
          name: 'Dec’16 - Feb’17',
          text: 'Token Sale'
        },
        {
          icon: '/assets/img/info/location.svg',
          name: 'Sydney, Australia',
          text: 'Founded in'
        }
      ],
      stat: [
        {
          value: '2016',
          title: 'Founded'
        },
        {
          value: '5',
          title: 'Number of Products'
        },
        {
          value: '710 113',
          title: 'Token Supply'
        },
        {
          value: '30+',
          title: 'Employees'
        }
      ]
    }
  },
  beforeDestroy () {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout)
    }
  },
  methods: {
    hoverProduct (productId) {
      if (this.blurTimeout) {
        clearTimeout(this.blurTimeout)
      }
      this.active = productId
    },
    blurProduct () {
      this.blurTimeout = setTimeout(() => {
        this.active = null
      }, 100)
    }
  }
}
