import moment from 'moment'
import skeleton from 'src/components/default/Skeleton/Skeleton.vue'

export default {
  props: ['post'],
  components: {
    skeleton
  },
  computed: {
    loading () {
      return !this.post
    },
    publishDateFormatted () {
      return moment(this.post.publish_at).format('MMMM DD')
    },
    minutes () {
      return Math.ceil(this.post.text_count / 1400)
    }
  }
}
