import aboutProductSection from 'src/components/default/AboutProductSection/AboutProductSection.vue'
import * as directives from 'src/directives'
import { productsSorted } from 'src/data/products'

export default {
  components: {
    aboutProductSection
  },
  directives: {
    ...directives
  },
  data () {
    return {
      products: productsSorted
    }
  }
}
