import * as directives from 'src/directives'

export default {
  directives: {
    ...directives
  },
  data () {
    return {
      partners: [
        {
          name: 'Dash',
          url: 'https://www.dash-embassy.org/',
          img: '/assets/img/partners/dash.png'
        },
        {
          name: 'CGU',
          url: 'https://cgu.io/',
          img: '/assets/img/partners/cgu.svg'
        },
        {
          name: 'DWF Labs',
          url: 'https://www.dwf-labs.com/',
          img: '/assets/img/partners/dwf_labs.svg'
        },
        {
          name: 'SingularityNET',
          url: 'https://singularitynet.io/',
          img: '/assets/img/partners/singularity_net.svg'
        },
        {
          name: 'Afford Capital',
          url: 'https://afford.capital/',
          img: '/assets/img/partners/afford_capital.svg'
        },
        {
          name: 'Chrono Fund',
          url: 'https://chrono.fund/',
          img: '/assets/img/partners/chrono_fund.svg'
        }
        // {
        //   name: 'bcoin',
        //   url: 'https://bcoin.io/',
        //   img: '/assets/img/partners/bcoin.png'
        // },
        // {
        //   name: 'Blackmoon',
        //   url: 'https://blackmoon.net/',
        //   img: '/assets/img/partners/blackmoon.png'
        // },
        // {
        //   name: 'Civic',
        //   url: 'https://www.civic.com/',
        //   img: '/assets/img/partners/civic.png'
        // },
        // {
        //   name: 'Emercoin',
        //   url: 'https://emercoin.com/',
        //   img: '/assets/img/partners/emercoin.png'
        // }
      ]
    }
  }
}
