import { render, staticRenderFns } from "./FeaturesPartial.htm?vue&type=template&id=fc70e22e&scoped=true&external"
import script from "./FeaturesPartial.js?vue&type=script&lang=js&external"
export * from "./FeaturesPartial.js?vue&type=script&lang=js&external"
import style0 from "./FeaturesPartial.scss?vue&type=style&index=0&id=fc70e22e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc70e22e",
  null
  
)

export default component.exports