import axios from 'axios'

/* eslint-disable-next-line no-undef */
const config = __APP_CONFIG__

export const BACKEND = axios.create(config.backendService.endpoint)
export const LX_BACKEND = axios.create({
  baseURL: 'https://api.laborx.com/',
  timeout: 6000
})
