export default {
  bind (el, binding, vnode) {
    vnode.clickOutsideListener = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value.bind(vnode.context)(event)
      }
    }
    document.body.addEventListener('click', vnode.clickOutsideListener)
  },
  unbind (el, binding, vnode) {
    if (vnode.clickOutsideListener) {
      document.body.removeEventListener('click', vnode.clickOutsideListener)
      vnode.clickOutsideListener = null
    }
  }
}
