import { copyToClipboard } from 'src/utils/common'

export default {
  props: {
    value: [ Number, String ],
    label: String,
    withoutText: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isCopied: false
    }
  },
  methods: {
    copyToClipboard () {
      copyToClipboard(this.value)
      this.isCopied = true
      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    }
  }
}
