import NoSsr from 'vue-no-ssr'
import ModalStack from 'src/partials/default/ModalStack/ModalStack.vue'

export default {
  name: 'app',
  components: {
    NoSsr,
    ModalStack
  }
}
