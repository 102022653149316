var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"milestones-wrap"},[_c('div',{directives:[{name:"infocus",rawName:"v-infocus",value:('slideInUp'),expression:"'slideInUp'"}],staticClass:"inner-wrap helper-slideInUp"},[_vm._m(0),_c('div',{staticClass:"tag-list flex"},_vm._l((_vm.categories),function(category,i){return _c('v-btn',{key:i,staticClass:"btn-purple tag",class:{active: _vm.activeType === category.type},attrs:{"color":"rgba(103, 132, 255, 0.1)"},on:{"click":function($event){return _vm.setActiveType(category)}}},[_vm._v(_vm._s(category.name))])}),1),_c('div',{staticClass:"mobile-tag-select"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"select-custom"},on),[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-select__slot"},[_c('div',{staticClass:"v-select__selections"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.activeCategory.name))])]),_c('div',{staticClass:"append-inner"},[_c('img',{attrs:{"src":"/assets/icons/arrow-down.svg","alt":"Arrow down icon","loading":"lazy"}})])])])])]}}])},[_c('v-list',_vm._l((_vm.categories),function(category,i){return _c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:i,staticClass:"v-list__tile v-list__tile--link",on:{"click":function($event){return _vm.setActiveType(category)}}},[_vm._v("\n            "+_vm._s(category.name)+"\n          ")])}),0)],1)],1)]),_c('div',{directives:[{name:"infocus",rawName:"v-infocus",value:('slideInUp'),expression:"'slideInUp'"}],staticClass:"swiper-outer-wrap flex helper-slideInUp"},[_vm._m(1),_c('div',{staticClass:"inner-wrap"},[_c('div',{ref:"swiper",staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.milestones),function(milestone,i){return _c('div',{key:i,staticClass:"swiper-slide",class:[milestone.class, {
              hidden: _vm.activeType && milestone.type !== _vm.activeType
            }]},[_c('span',{staticClass:"dot"}),_c('div',{staticClass:"timestamp"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(milestone.date))]),_c('div',{staticClass:"event"},[_vm._v(_vm._s(milestone.name))])])])}),0),_vm._m(2)])]),_vm._m(3)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"milestones-text-block"},[_c('h2',{staticClass:"title-milestones"},[_vm._v("Completed milestones")]),_c('p',{staticClass:"text"},[_vm._v("Over the course of the last three years, Chrono.tech has planned, funded, developed and launched a suite of blockchain and cryptocurrency-based products designed to revolutionise the world of recruitment and HR.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-grad"},[_c('div',{staticClass:"grad"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-control flex"},[_c('div',{staticClass:"control left"}),_c('div',{staticClass:"control right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-grad"},[_c('div',{staticClass:"grad"})])
}]

export { render, staticRenderFns }