import { render, staticRenderFns } from "./CopyItem.htm?vue&type=template&id=6fc3a573&scoped=true&external"
import script from "./CopyItem.js?vue&type=script&lang=js&external"
export * from "./CopyItem.js?vue&type=script&lang=js&external"
import style0 from "./CopyItem.scss?vue&type=style&index=0&id=6fc3a573&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc3a573",
  null
  
)

export default component.exports