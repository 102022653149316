import storiesPartial from 'src/partials/default/StoriesPartial/StoriesPartial.vue'
import teamPartial from 'src/partials/default/TeamPartial/TeamPartial.vue'
import leadershipPartial from 'src/partials/default/LeadershipPartial/LeadershipPartial.vue'

export default {
  components: {
    storiesPartial,
    teamPartial,
    leadershipPartial
  },
  head () {
    return {
      title: 'About Us',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'About Us, Mission, Vision and History'
        }
      ]
    }
  }
}
