import { mapActions, mapState } from 'vuex'
import * as directives from 'src/directives'
import breadcrumb from 'src/components/default/Breadcrumb/Breadcrumb.vue'
import communityPartial from 'src/partials/default/CommunityPartial/CommunityPartial.vue'
import timeSupplyPartial from 'src/partials/default/TimeSupplyPartial/TimeSupplyPartial.vue'
import DexsPartial from 'src/partials/default/DexsPartial/DexsPartial.vue'
import CexsPartial from 'src/partials/default/CexsPartial/CexsPartial.vue'
import TimeBridgePartial from 'src/partials/default/TimeBridgePartial/TimeBridgePartial.vue'
import TimeStakingPartial from 'src/partials/default/TimeStakingPartial/TimeStakingPartial.vue'

export default {
  directives: {
    ...directives
  },
  components: {
    breadcrumb,
    communityPartial,
    timeSupplyPartial,
    DexsPartial,
    CexsPartial,
    TimeBridgePartial,
    TimeStakingPartial
  },
  head () {
    return {
      title: 'How to buy Time Token (Chrono.tech) Official Guide',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'You can buy TIME Token (Chrono.tech) on several decentralised and centralised exchanges. Anyone can swap between TIME and a wide range of tokens and stablecoins using one of these DEXs and CEXs'
        }
      ]
    }
  },
  data () {
    return {
      breadcrumbLinks: [
        {
          title: 'Home',
          to: '/'
        },
        {
          title: 'How To Buy Time'
        }
      ],
      stat: [
        {
          value: '710 113',
          title: 'total supply'
        },
        {
          value: '7+',
          title: 'years on the market'
        },
        {
          value: '5+',
          title: 'working projects'
        },
        {
          value: '100 000 +',
          title: 'active users'
        }
      ],
      timeSupplyContent: {
        title: 'TIME Supply and Token Contracts',
        description: 'There’s a total of 710,113 TIME tokens, and no more can be created. TIME is available on three different blockchain networks:',
        items: [
          {
            name: 'Ethereum',
            link: '0x485d17A6f1B8780392d53D64751824253011A260',
            img: '/assets/img/ethereum_mainnet.svg'
          },
          {
            name: 'Polygon',
            link: '0xb970Bc1BD4fCD639c37aa8eFD6713eaDc577252f',
            img: '/assets/img/polygon.svg'
          },
          {
            name: 'BNB Chain',
            link: '0x3b198e26E473b8faB2085b37978e36c9DE5D7f68',
            img: '/assets/img/binance_smart_chain.svg'
          }
        ]
      }
    }
  },
  validations: {},
  serverPrefetch () {},
  mounted () {},
  computed: {
    ...mapState({
    })
  },
  methods: {
    ...mapActions({}),
    scrollToExchanges () {
      const yOffset = -170
      const element = document.getElementById('exchanges')
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }
}
