import { mapState, mapActions } from 'vuex'

export default {
  name: 'modal-stack',
  data () {
    return {
      bluredEl: []
    }
  },
  computed: {
    ...mapState({
      modals: ({ app }) => app.modalStack
    })
  },
  watch: {
    modals: {
      handler (value) {
        if (value.length > 0) {
          document.querySelector('html').classList.add('disabled-scroll')
          this.bluredEl = [...document.querySelector('.application--wrap').children].filter(el => el !== this.$el && el.id !== 'snackbar-stack')
          this.bluredEl.forEach(child => {
            child.style.filter = 'blur(4px)'
          })
        } else {
          document.querySelector('html').classList.remove('disabled-scroll')
          if (this.bluredEl.length) {
            this.bluredEl.filter(el => el !== this.$el).forEach(child => {
              child.style.filter = null
            })
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      closeModal: 'app/closeModal'
    })
  }
}
