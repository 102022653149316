import moment from 'moment'
import breadcrumb from 'src/components/default/Breadcrumb/Breadcrumb.vue'
import skeleton from 'src/components/default/Skeleton/Skeleton.vue'
import addThis from 'src/components/default/AddThis/AddThis.vue'

export default {
  components: {
    breadcrumb,
    skeleton,
    addThis
  },
  data () {
    return {
      activeQuestions: []
    }
  },
  computed: {
    post () {
      return this.$store.getters['blog/post'](this.$route.params.slug)
    },
    breadcrumbs () {
      return [
        {
          title: 'Home',
          to: '/'
        },
        {
          title: 'Blog',
          to: '/blog'
        },
        {
          title: this.post ? this.post.name : 'Post'
        }
      ]
    }
  },
  async asyncData ({ store, route, error }) {
    if (process.server) {
      const isLoaded = await store.dispatch('blog/getPost', route.params.slug)
      if (!isLoaded) {
        store.commit('app/setNotFound', true)
        error(new Error('Not Found'), 404)
      }
    }
  },
  async mounted () {
    if (!this.post) {
      const isLoaded = await this.$store.dispatch('blog/getPost', this.$route.params.slug)
      if (!isLoaded) {
        this.$store.commit('app/setNotFound', true)
      }
    }
  },
  methods: {
    toggleQuestion (i) {
      const elIndex = this.activeQuestions.indexOf(i)
      if (elIndex === -1) {
        this.activeQuestions.push(i)
      } else {
        this.activeQuestions.splice(elIndex, 1)
      }
    }
  },
  head () {
    const meta = []
    const script = []

    if (this.post) {
      meta.push({
        vmid: 'description',
        name: 'description',
        content: this.post.seo_description || this.post.description
      })
      meta.push({
        vmid: 'og:title',
        property: 'og:title',
        content: this.post.name
      })
      meta.push({
        vmid: 'og:description',
        property: 'og:description',
        content: this.post.description
      })
      meta.push({
        vmid: 'og:image',
        property: 'og:image',
        content: this.post.poster
      })
      meta.push({
        vmid: 'og:type',
        property: 'og:type',
        content: 'article'
      })
      meta.push({
        vmid: 'article:published_time',
        property: 'article:published_time',
        content: moment(this.post.publish_at).format()
      })
      meta.push({
        vmid: 'article:modified_time',
        property: 'article:modified_time',
        content: moment(this.post.updated_at).format()
      })
      meta.push({
        vmid: 'article:author',
        property: 'article:author',
        content: 'LaborX'
      })

      if (this.post && this.post.relations.Question.length) {
        script.push({
          vmid: 'FAQPage',
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: this.post.relations.Question.map(question => ({
              '@type': 'Question',
              name: question.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: question.answer
              }
            }))
          }
        })
      }
    }

    return {
      title: this.post ? (this.post.seo_title || this.post.name) : 'Loading',
      meta,
      script
    }
  }
}
