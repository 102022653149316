export default {
  isLiteral: true,
  inserted: (el, binding) => {
    let f = () => {
      let offset = []
      const percent = '32'
      const number = window.innerHeight / 100 * percent
      if (window.innerWidth < 450 && el.offsetHeight < number) {
        offset = 20
      } else {
        offset = 270
      }
      if (window.innerWidth < 450 && el.offsetHeight > number) {
        offset = 450
      }
      let rect = el.getBoundingClientRect()
      let inView = (
        rect.width > 0 &&
        rect.height > 0 &&
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight + offset || document.documentElement.clientHeight + offset)
      )
      if (inView) {
        el.classList.add(binding.value)
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
    f()
  }
}
